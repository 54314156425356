body {
  padding: 0;
  margin: 0;

}

body * {
    box-sizing: border-box;
}

.MuiCollapse-root.MuiCollapse-vertical.MuiTiptap-MenuBar-root.MuiTiptap-RichTextField-menuBar.MuiTiptap-MenuBar-sticky.MuiCollapse-entered {
  top: -8px;
}
